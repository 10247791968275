import React, {ReactNode} from 'react';
import styled from "@emotion/styled";

interface Props {
    firstLine: string,
    secondLine?: string,
    children?: ReactNode,
    fontSize?: string,
}

const Title: React.FC<Props> = ({
    firstLine,
    secondLine,
    children,
    fontSize,
}) => {
    return (
        <TitleTag fontSize={fontSize}>
            <p>{firstLine}</p>
            <span>{secondLine && secondLine}</span>
            {children && children}
        </TitleTag>
    );
};

interface TitleTagProp {
    fontSize?: string
}

const TitleTag = styled.div<TitleTagProp>`
    > p {
        font-family: YdestreetB;
        font-size: ${({ fontSize }) => fontSize ? fontSize : '60px'};
        line-height: 140%;
        background: linear-gradient(to bottom, #F1DDFF, #BBBDFF);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }
  
    span {
      font-family: YdestreetL;
      font-size: ${({ fontSize }) => fontSize ? fontSize : '60px'};
      line-height: 140%;
      color: #FFFFFF;
    }
`

export default Title;