import React, {useState} from 'react';
import SectionWrapper from "../../../components/sectionWrapper";
import Title from "../../../components/title";
import styled from "@emotion/styled";

const Contact = () => {
    const [username, setUsername] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');

    const sendData = async (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        const formData  = new FormData();
        formData.append('username', username);
        formData.append('email', email);
        formData.append('message', message);

        try {
            const response = await fetch('https://script.google.com/macros/s/AKfycbzQJF-V2eokd9HEOSU-QelmsUIhcm1KLuXaZg_yrffYi9aSz3t9fon_DuXtdneVdwkKug/exec', {
                method: 'POST',
                body: formData
            });

            response && alert('전송이 완료되었습니다.');
        } catch (e) {
            alert('문제가 발생했습니다. 잠시 후 다시 시도해주세요.')
        }
    }

    return (
        <SectionWrapper>
            <Form>
                <Title firstLine="CONTACT KEG" fontSize="24px" />
                <NameInputContainer>
                    <Label htmlFor="usr">Name</Label>
                    <Input type="text" id="usr" name="username" value={username} onChange={(e) => setUsername(e.target.value)} />
                </NameInputContainer>
                <EmailInputContainer>
                    <Label htmlFor="email">Email</Label>
                    <Input type="email" id="email" name="email" value={email} onChange={(e) => setEmail(e.target.value)} />
                </EmailInputContainer>
                <MessageInputContainer>
                    <Label htmlFor="comment">Message</Label>
                    <Input id="comment" name="message" value={message} onChange={(e) => setMessage(e.target.value)} />
                </MessageInputContainer>
                <Button onClick={(e) => sendData(e)}>SUBMIT</Button>
            </Form>
        </SectionWrapper>
    );
};

const Form = styled.form`
  width: 590px;
  background: linear-gradient(360deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.05) 100%);
  box-shadow: inset 0px 10px 25px rgba(230, 221, 254, 0.1);
  border-radius: 24px;
  padding: 40px;
  margin: 0 auto;
`

const NameInputContainer = styled.div`
    margin-top: 24px;
`

const EmailInputContainer = styled.div`
  margin-top: 16px;
`

const MessageInputContainer = styled.div`
  margin-top: 16px;
`

const Label = styled.label`
  font-family: Pretendard-Medium;
  line-height: 140%;
`

const Input = styled.input`
  width: 100%;
  height: 48px;
  background: #FFFFFF;
  box-shadow: 0px 25px 75px rgba(6, 7, 20, 0.1);
  border-radius: 10px;
  outline: none;
  border: none;
  padding: 0px 10px;
  margin-top: 8px;
`

const Button = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 510px;
  height: 54px;
  font-family: YdestreetB;
  line-height: 140%;
  background: linear-gradient(132.27deg, #F1DDFF 4.76%, #BBBDFF 83.69%);
  border-radius: 10px;
  padding: 16px 12px;
  margin-top: 24px;
`

export default Contact;