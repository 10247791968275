import React from 'react';
import SectionWrapper from "../../../components/sectionWrapper";
import styled from "@emotion/styled";
import Title from "../../../components/title";

const members = [
    {
        image: '/images/p1.png',
        name: 'Thomas Eakins',
        position: 'CEO'
    },
    {
        image: '/images/p2.png',
        name: 'Daniel Bright ',
        position: 'CTO'
    },
    {
        image: '/images/p3.png',
        name: 'Mariana Allesandro',
        position: 'Lead Designer'
    },
    {
        image: '/images/p4.png',
        name: 'Marcus Miller',
        position: 'Lead Web Developer'
    },
    {
        image: '/images/p5.png',
        name: 'Juliano Filho',
        position: 'Lead Blockchain Developer'
    },
    {
        image: '/images/p6.png',
        name: 'Claudio Bravo',
        position: 'Lead Game Developer'
    },
]

const Team = () => {
    return (
        <SectionWrapper>
            <TeamContainer>
                <Title firstLine="KEG TEAM" fontSize="48px" />
                <MemberContainer>
                    {members.map(member => (
                        <li key={member.name}>
                            <img src={member.image} alt="Member image"/>
                            <Name>{member.name}</Name>
                            <Position>{member.position}</Position>
                        </li>
                    ))}
                </MemberContainer>
            </TeamContainer>
        </SectionWrapper>
    );
};

const TeamContainer = styled.div`
  width: 1244px;
  text-align: center;
  margin: 0 auto;
`
const MemberContainer = styled.div`
  display: grid;
  grid-template-rows: repeat(2, 1fr);
  grid-template-columns: repeat(3, 1fr);
  gap: 24px;
  margin-top: 48px;
  
  li {
    //width: 398px;
    height: 308px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.05) 0%, rgba(255, 255, 255, 0) 100%);
    box-shadow: inset 0px 13.3533px 33.3832px rgba(230, 221, 254, 0.1);
    border-radius: 15px;
    padding: 20px 16px;
    
    img {
      width: 160px;
      height: 160px;
      border-radius: 50%;
    }
  }
`

const Name = styled.p`
  font-family: Pretendard-Regular;
  font-size: 20px;
  line-height: 140%;
  color: #F5F5F5;
  margin-top: 13px;
`

const Position = styled.p`
  font-family: YdestreetB;
  font-size: 20px;
  line-height: 140%;
  margin-top: 4px;
`

export default Team;