import React from 'react';
import SectionWrapper from "../../../components/sectionWrapper";
import Title from "../../../components/title";
import styled from "@emotion/styled";

const Partner = () => {
    return (
        <SectionWrapper>
            <PartnerContainer>
                <Title firstLine="KEG PARTNER" fontSize="48px" />
                <ImageContainer>
                    <li><img src="/images/coingecko.png" alt="Partner image"/></li>
                    <li><img src="/images/kryptowings.png" alt="Partner image"/></li>
                    <li><img src="/images/ivpsoft.png" alt="Partner image"/></li>
                    <li><img src="/images/binance.png" alt="Partner image"/></li>
                    <li><img src="/images/aws.png" alt="Partner image"/></li>
                    <li><img src="/images/opensea.png" alt="Partner image"/></li>
                    <li><img src="/images/shocklabs.png" alt="Partner image"/></li>
                    <li><img src="/images/lbank.png" alt="Partner image"/></li>
                    <li><img src="/images/brightInvest.png" alt="Partner image"/></li>
                </ImageContainer>
            </PartnerContainer>
        </SectionWrapper>
    );
};

const PartnerContainer = styled.div`
  width: 1244px;
  text-align: center;
  margin: 0 auto;
`

const ImageContainer = styled.div`
  display: grid;
  grid-template-rows: repeat(3, 1fr);
  grid-template-columns: repeat(3, 1fr);
  gap: 24px;
  margin-top: 48px;
  
  li {
    //width: 280px;
    height: 112px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.05) 0%, rgba(255, 255, 255, 0) 100%);
    box-shadow: inset 0px 13.3533px 33.3832px rgba(230, 221, 254, 0.1);
    border-radius: 15px;
    
    img {
      height: 100%;
    }
  }
`

export default Partner;