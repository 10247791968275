import React from 'react';
import SectionWrapper from "../../../components/sectionWrapper";
import MainContainer from "../../../components/mainContainer";
import Title from "../../../components/title";
import styled from "@emotion/styled";

const MetaVerse = () => {
    return (
        <SectionWrapper>
            <MainContainer>
                <>
                    <Contents>
                        <Title
                            firstLine="KEG 메타버스 세계에"
                            secondLine="오신 것을 환영합니다."
                            fontSize="48px"
                        >
                            <Description>
                                <p>블록체인과 WEB3.O의 융합을 통해<br/>새로운 차원의 메타버스를 경험하실 수 있습니다.</p>
                                <p>사용자에게 최고의 퀄리티와 재미를 제공하며,<br/>공정한 경쟁을 통해 보상을 받는 것이 목표입니다.</p>
                                <p>KEG만의 솔루션을 통해 판타지를 실현할 것 입니다.</p>
                            </Description>
                        </Title>
                    </Contents>
                    <ImageContainer>
                        <img src="/images/metaVerse.png" alt="MetaVerse image"/>
                    </ImageContainer>
                </>
            </MainContainer>
        </SectionWrapper>
    );
};

const Contents = styled.div``

const Description = styled.div`
  margin-top: 48px;
  
  p {
    font-family: Pretendard-Medium;
    font-size: 24px;
    line-height: 140%;
    margin-top: 16px;
    
    &:first-of-type {
      margin-top: 0px;
    }
  }
`

const ImageContainer = styled.div`
  width: 657px;
  height: 447px;
  
  img {
    width: 100%;
    height: 100%;
  }
`

export default MetaVerse;