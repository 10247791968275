import React from 'react';
import styled from "@emotion/styled";

const Footer = () => {
    return (
        <FooterTag>
            <FooterWrapper>
                <Logo>
                    <img src="/images/logo.svg" alt="king of esports game logo"/>
                </Logo>
                <FooterContents>
                    <Address>
                        <p>AlphaShare Pte.Ltd</p>
                        <p>3 Fraser Street #05-25</p>
                        <p>Duo Tower, Singapore 189352</p>
                    </Address>
                    <SNS>
                        <a href="#"><img src="/images/akar-icons_discord-fill.svg" alt="discord"/></a>
                        <a href="#"><img src="/images/akar-icons_twitter-fill.svg" alt="discord"/></a>
                        <a href="#"><img src="/images/akar-icons_instagram-fill.svg" alt="discord"/></a>
                        <a href="#"><img src="/images/ri_kakao-talk-fill.svg" alt="discord"/></a>
                    </SNS>
                </FooterContents>
            </FooterWrapper>
        </FooterTag>
    );
};

const FooterTag = styled.footer`
  width: 100vw;
  height: 254px;
  background-color: #05050A;
`

const FooterWrapper = styled.div`
  width: 1440px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  padding: 24px 60px 60px 60px;
  margin: 0 auto;
`

const Logo = styled.div`
  width: 366px;
  
  img {
    width: 100%;
  }
`

const FooterContents = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
`

const Address = styled.div`
  p {
    font-family: Pretendard-Regular;
    font-size: 12px;
    line-height: 140%;
  }
`

const SNS = styled.div`
  width: 144px;
  display: flex;
  justify-content: space-between;
  
  img {
    width: 24px;
  }
  
`

export default Footer;