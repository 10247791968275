import React from 'react';
import SectionWrapper from "../../../components/sectionWrapper";
import styled from "@emotion/styled";
import MainContainer from "../../../components/mainContainer";
import Title from "../../../components/title";


const StoryMain = () => {
    return (
        <SectionWrapper main={true}>
            <MainContainer>
                <>
                    <Contents>
                        <Title
                            firstLine="KING OF ESPORTS GAME"
                            secondLine="BATTLE P2E GAME"
                        >
                            <SubTitle>SAVE FOR WORLD</SubTitle>
                        </Title>
                        <ButtonContainer>
                            <p>ENTER THE KEG METAVERSE</p>
                            <ButtonList>
                                <li><img src="/images/play.svg" alt="Play"/><p>PLAY</p></li>
                                <li><img src="/images/enjoy.svg" alt="Enjoy"/><p>ENJOY</p></li>
                                <li><img src="/images/collect.svg" alt="Collect"/><p>COLLECT</p></li>
                                <li><img src="/images/earn.svg" alt="Earn"/><p>EARN</p></li>
                            </ButtonList>
                        </ButtonContainer>
                    </Contents>
                    <MainImage>
                        <img src="/images/mainUnit.gif" alt="Main unit"/>
                    </MainImage>
                </>
            </MainContainer>
        </SectionWrapper>
    );
};

const Contents = styled.div``

const SubTitle = styled.div`
  font-family: Pretendard-Regular;
  font-size: 36px;
  line-height: 140%;
  margin-top: 8px;
`

const ButtonContainer = styled.div`
  margin-top: 196px;
  
  p {
    font-family: Pretendard-SemiBold;
    font-size: 24px;
    line-height: 28px;
  }
`

const ButtonList = styled.ul`
  width: 688px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 24px;
  
  li {
    height: 53px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: linear-gradient(180deg, rgba(255, 255, 255, ) 0%, rgba(255, 255, 255, 0) 100%), linear-gradient(to bottom, #E6DDFE 25%, #E6DDFE 0%);
    border: 1px solid transparent;
    background-origin: border-box;
    background-clip: content-box, border-box;
    box-shadow: inset 0px 13.3533px 33.3832px rgba(230, 221, 254, 0.1);
    border-radius: 15px;
    padding: 6px 12px;
    cursor: pointer;
    
    p {
      font-family: YdestreetL;
      font-size: 20px;
      padding: 8px;
    }
    
    img {
      width: 24px;
      height: 24px;
      margin-right: 4px;
      
      svg {
        fill: white;
      }
    }
    
    &:hover {
      color: #05050A;
      background: linear-gradient(132.27deg, #F1DDFF 4.76%, #BBBDFF 83.69%);
      box-shadow: 10px 10px 40px rgba(255, 255, 255, 0.25), inset 0px 13.3533px 33.3832px rgba(230, 221, 254, 0.1);
      
      p {
        font-family: YdestreetB;
      }
        
      img {
        filter: invert(100%) sepia(3%) saturate(495%) hue-rotate(205deg) brightness(116%) contrast(92%);
      }
    }
  }
`

const MainImage = styled.div`
  position: relative;
  width: 440px;
  height: 440px;
  
  img {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0%;
    left: 0%;
  }

  //&::before {
  //  position: absolute;
  //  top: -30px;
  //  left: 30px;
  //  width: 100%;
  //  height: 100%;
  //  display: block;
  //  content: "";
  //  background: linear-gradient(360deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.05) 100%);
  //  box-shadow: inset 0px 8.63248px 21.5812px rgba(230, 221, 254, 0.1);
  //  border-radius: 24px;
  //}
`

export default StoryMain;