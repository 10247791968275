import React, {ReactElement} from 'react';
import styled from "@emotion/styled";

interface Prop {
    children: ReactElement;
    contents?: string;
}

const MainContainer: React.FC<Prop> = ({
    children,
    contents
}) => {
    return (
        <MainContainerTag contents={contents}>
            {children}
        </MainContainerTag>
    );
};

interface MainContainerTagProp {
    contents? : string
}

const MainContainerTag = styled.div<MainContainerTagProp>`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: ${({ contents }) => contents ? contents : 'space-between'};
    align-items: center;
`

export default MainContainer;