import React, {useState} from 'react';
import SectionWrapper from "../../../components/sectionWrapper";
import Title from "../../../components/title";
import styled from "@emotion/styled";

const RoadMap = () => {
    const [hover, setHover] = useState('0')

    return (
        <SectionWrapper>
            <RoadMapContainer>
                <Title firstLine="KEG ROADMAP" fontSize="48px" />
                <BoxContainer>
                   <LeftBox>
                       <RoadMapLi onMouseOver={() => setHover('1')} onMouseLeave={() => setHover('0')}>
                           <RoadMapTitle>1. Website<br/>release</RoadMapTitle>
                           <Since>2022.3rd</Since>
                           {hover === '1' && <LineTag></LineTag>}
                       </RoadMapLi>
                       <RoadMapLi onMouseOver={() => setHover('2')} onMouseLeave={() => setHover('0')}>
                           <RoadMapTitle>3. Website<br/>renewal</RoadMapTitle>
                           <Since>2023.1st</Since>
                           {hover === '2' && <LineTag></LineTag>}
                       </RoadMapLi>
                       <RoadMapLi onMouseOver={() => setHover('3')} onMouseLeave={() => setHover('0')}>
                           <RoadMapTitle>5. Game<br/>Alpha test</RoadMapTitle>
                           <Since>2024.3rd planning</Since>
                           {hover === '3' && <LineTag></LineTag>}
                       </RoadMapLi>
                       <RoadMapLi onMouseOver={() => setHover('4')} onMouseLeave={() => setHover('0')}>
                           <RoadMapTitle>7. Game<br/>Grand Open</RoadMapTitle>
                           <Since>2025.1st planning</Since>
                           {hover === '4' && <LineTag></LineTag>}
                       </RoadMapLi>
                   </LeftBox>
                    <Line></Line>
                   <RightBox>
                       <RoadMapLi onMouseOver={() => setHover('5')} onMouseLeave={() => setHover('0')}>
                           <RoadMapTitle>2. Exchange<br/>listed</RoadMapTitle>
                           <Since>2022.4th</Since>
                           {hover === '5' && <LineTag2></LineTag2>}
                       </RoadMapLi>
                       <RoadMapLi onMouseOver={() => setHover('6')} onMouseLeave={() => setHover('0')}>
                           <RoadMapTitle>4. Game<br/>Beta test</RoadMapTitle>
                           <Since>2024.2nd planning</Since>
                           {hover === '6' && <LineTag2></LineTag2>}
                       </RoadMapLi>
                       <RoadMapLi onMouseOver={() => setHover('7')} onMouseLeave={() => setHover('0')}>
                           <RoadMapTitle>6. Game<br/>Open Beta</RoadMapTitle>
                           <Since>2024.3rd planning</Since>
                           {hover === '7' && <LineTag2></LineTag2>}
                       </RoadMapLi>
                       <RoadMapLi onMouseOver={() => setHover('8')} onMouseLeave={() => setHover('0')}>
                           <RoadMapTitle>8. Staking<br/>System<br/>Launching</RoadMapTitle>
                           <Since>2025.1st planning</Since>
                           {hover === '8' && <LineTag2></LineTag2>}
                       </RoadMapLi>
                   </RightBox>
                </BoxContainer>
            </RoadMapContainer>
        </SectionWrapper>
    );
};

const RoadMapContainer = styled.div`
  width: 1440px;
  text-align: center;
  padding: 0px 110px;
  margin: 0 auto;
`

const BoxContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 48px;
`


const LeftBox = styled.div`
  position: relative;
  z-index: 10;
  width: 1265px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 40px;
  transform: translateX(-100px);
  
  > li {
    position: relative;
    
    &::before {
      position: absolute;
      top: calc(100% + 40px);
      left: calc(50%);
      width: 20px;
      height: 20px;
      display: block;
      background-color: #565560;
      border: 1px solid #000000;
      border-radius: 100px;
      content: "";
      transform: translate(-50%, calc(-50% + 3px));
      transition-duration: .3s;
    }

    &:hover {
      background: linear-gradient(132.27deg, #F1DDFF 4.76%, #BBBDFF 83.69%);
      box-shadow: 10px 10px 30px rgba(255, 255, 255, 0.25), inset 0px 13.3533px 33.3832px rgba(230, 221, 254, 0.1);
      color: #000000;

      &::before {
        background: linear-gradient(132.27deg, #F1DDFF 4.76%, #BBBDFF 83.69%);
        border: 1px solid #FFFFFF;
        box-shadow: 10px 10px 30px rgba(255, 255, 255, 0.25), inset 0px 13.3533px 33.3832px rgba(230, 221, 254, 0.1);
      }
    }
  }
`

const Line = styled.div`
  position: relative;
  width: 100%;
  height: 6px;
  background: linear-gradient(270deg, #ECE9FF 0%, rgba(236, 233, 255, 0) 94.7%);
  opacity: 0.35;
  transform: rotate(-180deg);
  z-index: 0;
`

const RightBox = styled.div`
  position: relative;
  z-index: 10;
  width: 1265px;
  display: flex;
  align-self: flex-end;
  justify-content: space-between;
  margin-top: 40px;
  transform: translateX(100px);

  > li {
    position: relative;
    z-index: 11;

    &::before {
      position: absolute;
      bottom: calc(100% + 40px);
      left: calc(50%);
      width: 20px;
      height: 20px;
      display: block;
      background-color: #565560;
      border: 1px solid #000000;
      border-radius: 100px;
      content: "";
      z-index: 10;
      transform: translate(-50%, calc(50% - 3px));
      transition-duration: .3s;
    }
    
    &:hover {
      background: linear-gradient(132.27deg, #F1DDFF 4.76%, #BBBDFF 83.69%);
      box-shadow: 10px 10px 30px rgba(255, 255, 255, 0.25), inset 0px 13.3533px 33.3832px rgba(230, 221, 254, 0.1);
      color: #000000;
      
      &::before {
        background: linear-gradient(132.27deg, #F1DDFF 4.76%, #BBBDFF 83.69%);
        border: 1px solid #FFFFFF;
        box-shadow: 10px 10px 30px rgba(255, 255, 255, 0.25), inset 0px 13.3533px 33.3832px rgba(230, 221, 254, 0.1);
      }
    }
  }
`

const RoadMapLi = styled.li`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: auto;
  height: 188px;
  font-size: 20px;
  line-height: 140%;
  color: #F5F5F5;
  text-align: center;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.05) 0%, rgba(255, 255, 255, 0) 100%);
  box-shadow: inset 0px 13.3533px 33.3832px rgba(230, 221, 254, 0.1);
  border-radius: 15px;
  padding: 24px 32px;
  cursor: pointer;
`

const RoadMapTitle = styled.p`
  font-family: YdestreetB;
`

const Since = styled.p`
font-family: Pretendard-Regular;
  margin-top: 4px;
`

const LineTag = styled.div`
  position: absolute;
  top: 100%;
  width: 1px;
  height: 40px;
  border: 1px solid #E6DDFE;
  border-style: dashed;
`

const LineTag2 = styled.div`
  position: absolute;
  bottom: 100%;
  width: 1px;
  height: 40px;
  border: 1px solid #E6DDFE;
  border-style: dashed;
`

export default RoadMap;