import React, {useRef} from 'react';
import SectionWrapper from "../../../components/sectionWrapper";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider, {Settings} from "react-slick";
import styled from "@emotion/styled";
import Title from "../../../components/title";

const News = () => {
    const settings: Settings = {
        infinite: true,
        speed: 1000,
        slidesToShow : 4,
        slidesToScroll: 4,
        autoplay: true,
        // lazyLoad: 'anticipated',
        arrows: false,
        centerMode: true,
        // centerPadding: '0px',
        // beforeChange: (current, next) => {
        //     setActiveSlide(next + 1)
        // }
    };
    const sliderRef = useRef(null);

    return (
        <SectionWrapper>
            <NewsContainer>
                <Title firstLine="KEG NEWS" fontSize="48px" />
                <Slider {...settings} ref={sliderRef}>
                    <NewsList>
                        <a href="https://www.buzzblockchain.com/2023/01/11/keg-project-brand-renewal-to-p2e-game-platform-king-of-esports-game/" target="_blank">
                            <Image><img src="/images/Rectangle%20756.png" alt=""/></Image>
                            <Date className="date">2023.01.11</Date>
                            <NewsListTitle className="title">KEG Project,<br/>‘Brand renewal to P2E...</NewsListTitle>
                        </a>
                    </NewsList>
                    <NewsList>
                        <a href="https://www.kryptowings.com/blockchain-based-p2e-game-king-of-esports-game-keg-launching/" target="_blank">
                            <Image><img src="/images/Auto%20Layout%20Vertical.png" alt=""/></Image>
                            <Date className="date">2023.01.11</Date>
                            <NewsListTitle className="title">Blockchain-based P2E game “KING OF ESPORTS...</NewsListTitle>
                        </a>
                    </NewsList>
                    <NewsList>
                        <a href="#">
                            <Image2></Image2>
                            {/*<Date className="date">2023.01.01</Date>*/}
                            <NewsListTitle2 className="title">COMING SOON</NewsListTitle2>
                        </a>
                    </NewsList>
                    <NewsList>
                        <a href="#">
                            <Image2></Image2>
                            {/*<Date className="date">2023.01.01</Date>*/}
                            <NewsListTitle2 className="title">COMING SOON</NewsListTitle2>
                        </a>
                    </NewsList>
                </Slider>
            </NewsContainer>
        </SectionWrapper>
    );
};

const NewsContainer = styled.div`
  width: 1440px;
  
  p {
    text-align: center;
  }
`

const NewsList = styled.div`
  width: 321px;
  height: 318px;
  box-shadow: inset 0px 10px 25px rgba(230, 221, 254, 0.1);
  border-radius: 24px;
  padding: 24px;
  margin-top: 60px;
  cursor: pointer;
  
  .date {
    background: linear-gradient(180deg, rgba(230, 221, 254, 0.25) 0%, rgba(230, 221, 254, 0) 100%);
  }
  
  .title {
    color: #565560;
  }
  
  &:hover {
    background: linear-gradient(360deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.05) 100%);
    box-shadow: inset 0px 10px 25px rgba(230, 221, 254, 0.1);
    border: 1px solid #E6DDFE25;
    
    .date {
      color: #000000;
      background: linear-gradient(132.27deg, #F1DDFF 4.76%, #BBBDFF 83.69%);
    }

    .title {
      color: #ffffff;
    }
  }
`

const Image = styled.div`
      width: 100%;
      height: 154px;
  
  img {
    width: 100%;
    height: 100%;
  }
`

const Image2 = styled.div`
  position: relative;
  width: 100%;
  height: 200px;
  background: #000000;

    &:after {
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
      content: "COMING SOON";
      font-family: YdestreetL;
      color: #565560;
    }
`

const Date = styled.div`
  display: inline-block;
  padding: 6px 10px;
  border-radius: 100px;
  margin-top: 24px;
`

const NewsListTitle = styled.div`
  font-family: YdestreetB;
  font-size: 15px;
  line-height: 140%;
  margin-top: 8px;
`

const NewsListTitle2 = styled.div`
  font-family: YdestreetB;
  font-size: 15px;
  line-height: 140%;
  margin-top: 24px;
`

export default News;