import React, {useState} from 'react';
import styled from "@emotion/styled";
import {MEDIA} from "../../config/styles";

const pages = [
    {
        title: 'STORY',
        comingSoon: false,
        navigate: '#story1'
    },
    {
        title: 'NFT',
        comingSoon: true,
        navigate: '#'
    },
    {
        title: 'MARKETPLACE',
        comingSoon: true,
        navigate: '#'
    },
    {
        title: 'TOKEN',
        comingSoon: false,
        navigate: '#token1'
    },
    {
        title: 'ROADMAP',
        comingSoon: false,
        navigate: '#roadmap'
    },
    {
        title: 'TEAM',
        comingSoon: false,
        navigate: '#team'
    },
    {
        title: 'PARTNER',
        comingSoon: false,
        navigate: '#partner'
    },
    {
        title: 'CONTACT',
        comingSoon: false,
        navigate: '#contact'
    },
]

const Header = () => {
    const [isShowNav, setIsShowNav] = useState(false);

    return (
        <HeaderTag>
            <HeaderWrapper>
                <Logo>
                    <a href="/story1"><img src="/images/logo.svg" alt="king of esports game logo"/></a>
                </Logo>
                <Nav isShowNav={isShowNav}>
                    <CloseBtn onClick={() => setIsShowNav(false)}><img src="/images/close.svg" alt="Close button"/></CloseBtn>
                    <ul>
                        {pages.map(list => (
                            <a href={list.navigate}><NavList key={list.title} comingSoon={list.comingSoon} onClick={() => setIsShowNav(false)}>{list.title}</NavList></a>
                        ))}
                        <Whitepaper><a href="/whitepaper/KEG_Whitepaper_230112.pdf" target="_blank">WHITEPAPER</a></Whitepaper>
                    </ul>
                </Nav>
                <HamburgerMenu onClick={() => setIsShowNav(true)}>
                    <img src="/images/hamburger.svg" alt="Hamburger menu"/>
                </HamburgerMenu>
            </HeaderWrapper>
        </HeaderTag>
    );
};

const HeaderTag = styled.header`
  position: fixed;
  top: 0%;
  left: 0%;
  width: 100%;
  z-index: 10;
  background: rgba(5, 5, 10, 0.6);
`

const HeaderWrapper = styled.div`
  width: 1440px;
  height: 90px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;

  ${MEDIA.TABLET} {
    width: 100%;
    padding: 0px 16px;
  }
`

const Logo = styled.div`
  width: 366px;
  
  img {
    width: 100%;
  }
`

interface Prop {
    isShowNav: boolean
}

const Nav = styled.nav<Prop>`
  width: 1012px;
  
  ul {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-family: YdestreetL;
      transition-duration: .3s;
      cursor: pointer;
  }

  ${MEDIA.TABLET} {
    position: fixed;
    top: 0%;
    left: 0%;
    display: ${({ isShowNav }) => isShowNav ? 'block' : 'none'};
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.9);

    ul {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: center;
    }
  }
`

const CloseBtn = styled.div`
  display: none;
  ${MEDIA.TABLET} {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    height: 32px;
    cursor: pointer;
    
    img {
      width: 32px;
      height: 100%;
      margin: 12px 16px 0px 0px;
    }
  }
`

interface NavListProp {
  comingSoon: boolean
}

const NavList = styled.li<NavListProp>`
  position: relative;
  font-size: 16px;
  letter-spacing: 0.15px;
  padding: 8px 0px;
  cursor: pointer;
  
  &:hover {
    font-family: YdestreetB;

    &::after {
      position: absolute;
      bottom: 100%;
      left: 50%;
      min-width: 116px;
      display: ${({ comingSoon }) => comingSoon ? 'block' : 'none'};
      font-family: YdestreetB;
      font-size: 10px;
      text-align: center;
      background-color: #FF0000;
      border-radius: 10px;
      content: "Coming Soon";
      transform: translateX(-50%);
      padding: 4px 8px;
    }
  }
  
  ${MEDIA.TABLET} {
    font-size: 18px;
  }
`

const Whitepaper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  letter-spacing: 0.15px;
  border: 1px solid #F5F5F5;
  border-radius: 100px;
  transition-duration: .3s;
  padding: 8px 12px;
  cursor: pointer;
  
  &:hover {
    color: #05050A;
    background-color: #F5F5F5;
  }

  ${MEDIA.TABLET} {
    font-size: 18px;
  }
`

const HamburgerMenu = styled.div`
    display: none;
    ${MEDIA.TABLET} {
      display: block;
      width: 24px;
      height: 24px;
      cursor: pointer;
    }
`

export default Header;