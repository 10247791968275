import React, {ReactNode} from 'react';
import SectionWrapper from "../../../components/sectionWrapper";
import MainContainer from "../../../components/mainContainer";
import styled from "@emotion/styled";
import Title from "../../../components/title";

interface ItemBoxProps {
    title: string,
    children: ReactNode,
}

const ItemBox: React.FC<ItemBoxProps> = ({
    title,
    children
}) => {
    return (
        <ItemBoxContainer>
            <ItemBoxContents>
                <Title firstLine={title} fontSize="48px" />
                {children}
            </ItemBoxContents>
            <ImageContainer>
                <img src="/images/token.gif" alt="Token image"/>
            </ImageContainer>
        </ItemBoxContainer>
    )
}

const TokenInfo = () => {
    return (
        <SectionWrapper>
            <MainContainer>
                <ItemBoxGrid>
                    <ItemBox
                        title="KEG 토큰"
                    >
                        <Description>
                            <p>KEG토큰은 플랫폼<br/>
                                내 기축통화로 쓰이는 메인토큰이며,<br/>
                                게임 이벤트, 랭킹, 스테이킹 등을<br/>
                                통해 획득이 가능합니다.</p>
                            <p>1. 거버넌스<br/>2. 스테이킹 서비스<br/>3. NFT 거래(수수료 소각)<br/>
                                4. NFT강화(강화 시 소각)<br/>5. 시즌 리그 KEG 패스 구매</p>
                        </Description>
                    </ItemBox>
                </ItemBoxGrid>
            </MainContainer>
        </SectionWrapper>
    );
};

const ItemBoxGrid = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  //display: grid;
  //grid-template-columns: repeat(2, 1fr);
  //gap: 36px;
`

const ItemBoxContainer = styled.div`
    width: 888px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: linear-gradient(360deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.05) 100%);
    box-shadow: inset 0px 10px 25px rgba(230, 221, 254, 0.1);
    border-radius: 24px;
    border-radius: 24px;
    padding: 40px;
`

const ItemBoxContents = styled.div`
  text-align: center;
`

const Description = styled.div`
    line-height: 140%;
    margin-top: 24px;
  
    p {
      font-size: 24px;
      line-height: 140%;
      
      &:last-of-type {
        font-size: 20px;
        margin-top: 20px;
      }
    }
`

const ImageContainer = styled.div`
    width: 399px;
    height: 399px;
    border-radius: 24px;
    overflow: hidden;  
  
  img {
    width: 100%;
    height: 100%;
  }
`

export default TokenInfo;