import React from 'react';
import SectionWrapper from "../../../components/sectionWrapper";
import MainContainer from "../../../components/mainContainer";
import Title from "../../../components/title";
import styled from "@emotion/styled";

const Pvp = () => {
    return (
        <SectionWrapper>
            <MainContainer contents="flex-start">
                <>
                    <ImageContainer>
                        <img src="/images/pvp.png" alt="PVP image"/>
                    </ImageContainer>
                    <Contents>
                        <Title
                            firstLine="KEG PVP 모드"
                            fontSize="48px"
                        >
                            <Description>
                                <p>KEG 메타버스에서 순위권에 도전해보세요.</p>
                                <p>다른 무기 장착을 통해<br/>다양한 맵에서 전투하고 보상을 획득할 수 있습니다.</p>
                                <p>게임의 재미와 보상을 동시에 느낄 수 있습니다.</p>
                            </Description>
                        </Title>
                    </Contents>
                </>
            </MainContainer>
        </SectionWrapper>
    );
};

const Contents = styled.div`
  margin-left: 100px;
`

const Description = styled.div`
  margin-top: 48px;
  
  p {
    font-family: Pretendard-Medium;
    font-size: 24px;
    line-height: 140%;
    margin-top: 16px;
    
    &:first-of-type {
      margin-top: 0px;
    }
  }
`

const ImageContainer = styled.div`
  width: 657px;
  height: 447px;
  
  img {
    width: 100%;
    height: 100%;
  }
`

export default Pvp;