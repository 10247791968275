import React from 'react';
import styled from "@emotion/styled";
import StoryMain from '../../pages/main/story/storyMain';
import MetaVerse from "./story/metaVerse";
import Pvp from "./story/pvp";
import Robot from "./story/robot";
import TokenInfo from "./token/tokenInfo";
import Economy from "./token/economy";
import RoadMap from "./roadMap";
import Team from "./team";
import Partner from "./partner";
import Contact from "./contact";
import $, {fullpage} from 'jquery';
import 'fullpage.js/vendors/scrolloverflow';
import 'fullpage.js';
import 'fullpage.js/dist/jquery.fullpage.min.css';
import Footer from "../../components/footer";
import News from "./team/news";

const Main = () => {
    $((): void => {
        $('#fullpage').fullpage({
            scrollOverflow: true,
            navigation: false,
            anchors: ['story1', 'story2', 'story3', 'story4', 'token1', 'token2', 'roadmap', 'news', 'team', 'partner', 'contact', 'footer'],
            scrollHorizontally: true,
            showActiveTooltip: true,
            scrollingSpeed: 800,
            fitToSection: true,
            fitToSectionDelay: 1200,
            responsiveWidth: 1280,

            // 화면별 전환
            afterLoad: function (index, anchorLink) {
                if (anchorLink === 1) {
                    // 화면별 넣고 싶은 이벤트
                } else if (anchorLink === 2) {
                    // 화면별 넣고 싶은 이벤트
                } else if (anchorLink === 3) {
                    // 화면별 넣고 싶은 이벤트
                }
            },
            afterResponsive: () => {
                fullpage.setAutoScrolling(false);
            }
        })

    });

    return (
        <MainTag>
            <div id="fullpage">
                <div className="section"><StoryMain /></div>
                <div className="section"><MetaVerse /></div>
                <div className="section"><Pvp /></div>
                <div className="section"><Robot /></div>
                <div className="section"><TokenInfo /></div>
                <div className="section"><Economy /></div>
                <div className="section"><RoadMap /></div>
                <div className="section"><News /></div>
                <div className="section"><Team /></div>
                <div className="section"><Partner /></div>
                <div className="section"><Contact /></div>
                <div className="section fp-auto-height"><Footer /></div>
            </div>
        </MainTag>
    );
};

const MainTag = styled.div`
  width: 100%;    
`

export default Main;