import React, {useState} from 'react';
import SectionWrapper from "../../../components/sectionWrapper";
import MainContainer from "../../../components/mainContainer";
import Title from "../../../components/title";
import styled from "@emotion/styled";

const btnList = [
    {
        name: 'S',
        description: 'Sale: 200,000,000',
        percent: '(40%)',
        image: '/images/sale.png',
        title: 'Sale'
    },
    {
        name: 'M',
        description: 'Marketing & Development: 50,000,000',
        percent: '(10%)',
        image: '/images/marketing.png',
        title: 'Marketing'
    },
    {
        name: 'E',
        description: 'Eco system: 75,000,000',
        percent: '(15%)',
        image: '/images/eco.png',
        title: 'Eco system'
    },
    {
        name: 'L',
        description: 'Liquidity Supply: 37,500,000',
        percent: '(7.5%)',
        image: '/images/liquidity.png',
        title: 'Liquidity'
    },
    {
        name: 'S',
        description: 'Staking Rewards: 50,000,000',
        percent: '(10%)',
        image: '/images/staking.png',
        title: 'Staking'
    },
    {
        name: 'T',
        description: 'Team: 37,500,000',
        percent: '(7.5%)',
        image: '/images/team.png',
        title: 'Team'
    },
    {
        name: 'R',
        description: 'Reserve: 50,000,000',
        percent: '(10%)',
        image: '/images/reserve.png',
        title: 'Reserve'
    }
]

interface BtnProps {
    name: string
    description: string,
    percent: string,
    setHoverItem: React.Dispatch<React.SetStateAction<string>>,
    title: string
}

const Btn: React.FC<BtnProps> = ({
    name,
    description,
    percent,
    setHoverItem,
    title
}) => {
    return (
        <ButtonList onMouseOver={() => setHoverItem(title)}>
            <Name className="Name">{name}</Name>
            <Info>
                <Description>{description}</Description>
                <Percent>{percent}</Percent>
            </Info>
        </ButtonList>
    )
}

const Economy = () => {
    const [hoverItem, setHoverItem] = useState('Sale');

    return (
        <SectionWrapper>
            <EconomyContainer>
                <>
                    <Title firstLine="TOKEN ECONOMY" fontSize="48px" />
                    <ContentsContainer>
                        <Contents>
                            <Network>Network : Binance Smart Chain</Network>
                            <Total>Total : 500,000,000</Total>
                            <ButtonContainer>
                                {btnList.map(list => (
                                    <Btn
                                        name={list.name}
                                        description={list.description}
                                        percent={list.percent}
                                        setHoverItem={setHoverItem}
                                        title={list.title}
                                        key={list.image}
                                    />
                                ))}
                            </ButtonContainer>
                        </Contents>
                        <ImageContainer>
                            {btnList.map(list => (
                                <img
                                    src={list.image}
                                    alt={list.title}
                                    style={list.title === hoverItem ? {display: "block"} : {display: "none"}}
                                    key={list.image}
                                />
                            ))}
                        </ImageContainer>
                    </ContentsContainer>
                </>
            </EconomyContainer>
        </SectionWrapper>
    );
};

const EconomyContainer = styled.div``

const ContentsContainer = styled.div`
  display: flex;
  justify-content: space-between;
`

const Contents = styled.div``

const Network = styled.div`
  font-family: Pretendard-Medium;
  color: #FFFFFF;
  font-size: 20px;
  line-height: 140%;
  margin-top: 24px;
`

const Total = styled.div`
  font-family: Pretendard-Medium;
  font-size: 20px;
  line-height: 140%;
  margin-top: 4px;
`

const ButtonContainer = styled.div`
  width: 622px;
  display: Grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 20px;
  //justify-content: space-between;
  //align-items: flex-start;
  margin-top: 36px;
`

const ButtonList = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  
  &:hover .Name { 
      color: #000000;
      background: linear-gradient(132.27deg, #F1DDFF 4.76%, #BBBDFF 83.69%);
      box-shadow: 10px 10px 30px rgba(255, 255, 255, 0.25), inset 0px 13.3533px 33.3832px rgba(230, 221, 254, 0.1);
  }
`

const Name = styled.div`
    width: 60px;
    height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: YdestreetB;
  font-size: 19px;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.05) 0%, rgba(255, 255, 255, 0) 100%);
  box-shadow: inset 0px 13.3533px 33.3832px rgba(230, 221, 254, 0.1);
  border-radius: 15px;
  transition-duration: .3s;
`

const Info = styled.div`
  display: flex;
  margin-left: 16px`

const Description = styled.p`
  font-family: Pretendard-SemiBold;
  font-size: 24px;
`

const Percent = styled.p`
 font-family: Pretendard-Medium;
  font-size: 24px;
  margin-left: 4px;
`

const ImageContainer = styled.div`
  position: relative;
  width: 660px;
  height: 660px;
  
  img {
    position: absolute;
    top: 0%;
    left: 0%;
    width: 100%;
    height: 100%;
    transition-duration: .3s;
  }
`

export default Economy;