import React from 'react';
import SectionWrapper from "../../../components/sectionWrapper";
import MainContainer from "../../../components/mainContainer";
import Title from "../../../components/title";
import styled from "@emotion/styled";

const Robot = () => {
    return (
        <SectionWrapper>
            <MainContainer>
                <>
                    <Contents>
                        <Title
                            firstLine="KEG ROBOT"
                            fontSize="48px"
                        >
                            <Description>
                                <p>KEG의 핵심 KEG ROBOT입니다.<br/>NFT로 발행된 로봇을 이용하여 게임 내에서 배틀, 레이스 등<br/>다양한 활동을 할 수 있습니다.</p>
                                <p>보상을 통해 로봇과 무기를 강화하고<br/>등급을 올려 한층 더 나은 혜택을 누릴 수 있습니다.</p>
                            </Description>
                        </Title>
                    </Contents>
                    <ImageContainer>
                        <img src="/images/robot.png" alt="Robot image"/>
                    </ImageContainer>
                </>
            </MainContainer>
        </SectionWrapper>
    );
};

const Contents = styled.div``

const Description = styled.div`
  margin-top: 48px;
  
  p {
    font-family: Pretendard-Medium;
    font-size: 24px;
    line-height: 140%;
    margin-top: 16px;
    
    &:first-of-type {
      margin-top: 0px;
    }
  }
`

const ImageContainer = styled.div`
  width: 657px;
  height: 447px;
  
  img {
    width: 100%;
    height: 100%;
  }
`

export default Robot;