import React, {ReactElement} from 'react';
import styled from "@emotion/styled";

interface Prop {
    main?: boolean;
    children: ReactElement;
}

const SectionWrapper: React.FC<Prop> = ({ main= false, children}) => {
    return (
        <Background main={main}>
            <Wrapper>
                {children}
            </Wrapper>
        </Background>
    );
};

interface BackgroundProp {
    main: boolean;
}

const Background = styled.section<BackgroundProp>`
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: ${({ main }) => main ? 'url("/images/1920.png")' : 'url("/images/bg.png")'};
  background-size: cover;
`

const Wrapper = styled.div`
  width: 1440px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
`

export default SectionWrapper;